/* Sidebar.css */
#sidebar .nav-item .nav-link {
    display: flex;
    align-items: center;
    border-radius: 12px !important;
    margin-bottom: 10px;
}

#sidebar .nav-item.active .nav-link {
    background-color: #6033FF;
}

#sidebar .nav-item .nav-link:hover {
    background-color: #25272e;
}

.no-scroll {
    overflow: hidden;
}

/* Full-screen sidebar styles for mobile */
@media (max-width: 992px) {
    #sidebar {
        position: fixed;
        width: calc(100% - 32px);
        top: 56px;
        left: 16px;
        transform: translateX(-105%);
        transition: transform 0.3s ease-in-out;
        z-index: 1040;
        background-color: #25272E;
        height: calc(100vh - 72px); 
        border-radius: 16px;
        overflow-y: auto;
        padding: 16px;
        margin-top: 16px;
    }

    #sidebar.visible {
        transform: translateX(0);
    }

    #sidebar .nav-item .nav-link {
        border-radius: 0;
    }

    #sidebar .close-btn {
        display: block;
    }
}

@media (min-width: 992px) {
    #sidebar {
        position: static;
        transform: translateX(0);
        height: auto;
    }
}
