/* chat-list-item.css */
.chat-item {
    padding: 12px;
    transition: background-color 0.3s;
    margin-bottom: 10px;
    border-radius: 12px;
}

.chat-item.selected {
    background-color: #6033FF !important;
}

.chat-item:not(.selected):hover {
    background-color: rgba(19, 19, 19, 0.5);
    border-radius: 12px;
    cursor: pointer;
}

.user-avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
}

.user-avatar img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.user-name {
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.last-message {
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.time-ago {
    font-size: 12px;
    white-space: nowrap;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-list-item-message {
    min-width: 0;
    overflow: hidden;
}
