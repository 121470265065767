.profile-settings-input {
    margin-bottom: 15px;
}

.profile-settings-label {
    position: relative;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: white;
    margin-bottom: 4px;
}

.profile-settings-input-description {
    font-size: 14px;
    font-style: normal;
    color: rgb(194, 194, 194);
    margin-bottom: 16px;
}

/* Hide the mobile view description on non-mobile devices */
@media (min-width: 576px) {
    .profile-settings-input-description {
        display: none;
    }
}

.profile-settings-text-input,
.profile-settings-textarea {
    background-color: #131313;
    color: white;
    border: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    border-radius: 12px;
    padding: 16px;
    width: 100%;
}

.profile-settings-text-input:focus,
.profile-settings-textarea:focus {
    background-color: #131313;
    box-shadow: none;
    color: white;
}

.profile-settings-text-input:disabled,
.profile-settings-textarea:disabled {
    background-color: #131313;
    box-shadow: none;
    color: white;
}

.profile-settings-text-input::placeholder,
.profile-settings-textarea::placeholder {
    color: #B1B5C1;
    opacity: 1;
}

/* For cross-browser compatibility */
.profile-settings-text-input::-webkit-input-placeholder,
.profile-settings-textarea::-webkit-input-placeholder {
    color: #B1B5C1;
}

.profile-settings-text-input:-moz-placeholder,
.profile-settings-textarea:-moz-placeholder {
    color: #B1B5C1;
    opacity: 1;
}

.profile-settings-text-input::-moz-placeholder,
.profile-settings-textarea::-moz-placeholder {
    color: #B1B5C1;
    opacity: 1;
}

.profile-settings-text-input:-ms-input-placeholder,
.profile-settings-textarea:-ms-input-placeholder {
    color: #B1B5C1;
}

.input-suffix-wrapper {
    display: flex;
    align-items: center;
}

.input-suffix {
    margin-left: 8px;
    color: white;
}
