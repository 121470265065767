.message-box {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: black;
    padding: 32px;
    border-radius: 16px;
}

.message-text {
    width: 100%;
}

@media (min-width: 1200px) {
    .message-text {
        width: 50%;
    }
}
