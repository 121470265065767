/* chat-message-right.css */

.chat-message-list-item {
    margin-top: 20px;
    margin-bottom: 10px;
}

.chat-message-list-item-user-avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
}

.chat-message-list-item-user-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat-message-list-item-box {
    display: inline-block;
    max-width: 80%;
    padding: 10px 16px;
    border-radius: 12px;
    word-wrap: break-word;
    background-color: #6033FF;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: end;
}

.chat-message-list-item-message {
    color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    text-align: justify;
    font-family: 'Helvetica', sans-serif;
}

.chat-message-list-item-message p {
    margin: 0 !important;
}

.chat-message-list-item-date-time {
    margin-top: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: #B1B5C1;
    text-align: end;
}

.chat-message-image {
    max-width: 100%;
    max-height: 200px;
    border-radius: 8px;
}

@media screen and (max-width: 768px) {
    .chat-message-list-item-box {
        max-width: none;
    }
    .chat-message-list-item-user-avatar {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .chat-input-message-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
