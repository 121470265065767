/* ChatList.css */
.chat-list .header {
    padding: 10px;
}

.chat-list-headline {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.chat-list-divider {
    height: 1px;
    align-self: stretch;
    background: #2E2E3F;
}

.chat-list .rounded-box {
    width: 44px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #2E2E3F;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.chat-list .rounded-box img {
    max-width: 100%;
    max-height: 100%;
}

.clickable-icon {
    cursor: pointer;
    color: #B1B5C1;
}

.chat-list {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-list-content {
    flex-grow: 1;
    padding-right: 10px;
    overflow: auto;
}

.chat-category-dropdown-menu {
    background-color: #131313;
    padding: 8px;
}

.chat-category-dropdown-menu .dropdown-item {
    color: #B1B5C1; 
    border-radius: 8px;
}

.chat-category-dropdown-menu .dropdown-item:hover, 
.chat-category-dropdown-menu .dropdown-item:focus {
    background-color: #2c2c2c;
}

.chat-category-dropdown-menu .dropdown-item.active,
.chat-category-dropdown-menu .dropdown-item:active {
    background-color: #6033FF;
}


.chat-category-dropdown-menu-toggle {
    color: #B1B5C1 !important;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.chat-category-dropdown-menu-toggle::after {
    display: none;
}

.chat-category-dropdown-menu-toggle:focus {
    box-shadow: none;
    outline: none;
}

.chat-category-dropdown-menu-toggle:active {
    background-color: transparent;
}

/* Hide the scrollbar on mobile for .chat-list-content */
@media screen and (max-width: 768px) {
    .chat-list-content::-webkit-scrollbar {
        display: none;
    }
}
