.settings-button {
    color: white;
    border-radius: 8px;
    border: 1px solid #B1B5C1;
}

.settings-item-title {
    font-size: 18px;
    color: white;
}

.settings-item-subtitle {
    font-size: 14px;
    color: #B1B5C1;
}

.settings-item {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space between title/subtitle and button */
}

@media (max-width: 768px) {
    .settings-item {
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
    }

    .settings-button {
        margin-top: 10px;
    }
}
