/* ProfileSettingsDescription.css */
.profile-settings-label {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: white;
    margin-bottom: 16px;
}

.profile-settings-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #131313;
    border-radius: 8px;
    flex-wrap: wrap;
}

.voice-save-container {
    display: flex;
    align-items: center;
}

.description-textarea {
    width: 100%;
    min-height: 350px;
    border: none;
    background-color: #131313;
    resize: none;
    color: white;
    padding: 10px;
    border-radius: 4px;
    margin-right: 10px;
}

textarea:focus,
.description-textarea:focus {   
    background-color: #131313;
    border-color: none;
    box-shadow: none;
    outline: 0 none;
    color: white;
}

.description-icons {
    background-color: transparent;
    display: flex;
    gap: 5px;
    justify-content: flex-start;
}

.description-icon-btn {
    font-size: 24px;
    color: #b1b5c1;
    border: none;
    cursor: pointer;
}

.voice-btn {
    background-color: #6033FF;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-right: 8px;
}

.save-btn {
    margin-left: auto; 
    background-color: #25272E;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    order: 3;
}

.description-textarea::placeholder {
    color: #B1B5C1; /* Replace with the color you want */
    opacity: 1; /* Optional: Adjust the opacity as needed */
}

/* For cross-browser compatibility */
.description-textarea::-webkit-input-placeholder {
    color: #B1B5C1;
}

.description-textarea:-moz-placeholder {
    color: #B1B5C1;
    opacity: 1;
}

.description-textarea::-moz-placeholder {
    color: #B1B5C1;
    opacity: 1;
}

.description-textarea:-ms-input-placeholder {
    color: #B1B5C1;
}

.file-preview {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.file-preview-image {
    margin-right: 8px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
}

.file-preview-name {
    color: white;
    margin-right: 10px;
}

.file-delete-icon {
    color: #E31D1D;
    cursor: pointer;
}
