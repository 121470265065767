body {
  margin: 0;
  font-family: 'Space Grotesk';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131313;
  padding-bottom: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-custom-purple {
  background-color: #6033FF !important;
  border: none;
  box-shadow: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 16px;
  transition: transform 0.3s ease;
}

.btn-custom-purple:hover {
  transform: scale(0.95);
}

.wallet-adapter-button-trigger {
  background-color: #6033FF !important;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  box-shadow: none;
}

.wallet-adapter-button-trigger:hover {
  opacity: 0.9;
}

/* Style the vertical scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #25272E; /* Background color of the track */
}

/* Thumb */
::-webkit-scrollbar-thumb {
  background: #60636D; /* Color of the thumb */
  border-radius: 8px; /* Rounded corners for the thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #808489; /* Color of the thumb on hover */
}

.tooltip-inner {
  max-width: 350px;
  width: 350px; 
}

.tooltip-info-icon {
  color: white;
  font-size: 18px;
  cursor: help;
}