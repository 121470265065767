.voice-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 300px;
}

.top-section {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.voice-message {
    display: flex;
    flex-grow: 1;
    align-items: center;
    background-color: #25272E;
    border-radius: 30px;
    padding: 10px;
    width: 100%;
}

.play-pause-btn {
    background: none;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: white;
}

.waveform-timer-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#waveform {
    flex-grow: 1;
    margin-left: 10px;
}

.timer {
    color: white;
    font-size: 12px;
    margin-top: 5px;
}

.creation-date {
    color: #B1B5C1;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 50px;
}
