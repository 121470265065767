.qa-box {
    background-color: #131313;
    border-radius: 16px;
    padding: 24px;
    color: white;
    min-height: 200px;
    width: 90%;
}

.qa-grid-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: white;
}

.qa-title {
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: white !important;
}

.qa-text {
    color: #B1B5C1 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}

.price-box {
    padding: 8px 12px;
    border: 1px solid #25272E;
    background-color: transparent;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.btn-get-access {
    background-color: #6033FF !important;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 16px;
    transition: transform 0.3s ease;
}

.btn-get-access:hover {
    transform: scale(0.95);
}

.btn-share {
    background-color: #25272E !important;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 16px;
    transition: transform 0.3s ease;
}

.btn-share:hover {
    transform: scale(0.95);
}