/* VoiceMessageInput.css */
.voice-message-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #131313;
    border-radius: 8px;
    flex-wrap: wrap;
}

.voice-message-input-button-container {
    display: flex;
    align-items: center;
}

.voice-message-input-button-container .send-btn {
    background-color: #6033FF;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-right: 8px;
}

.voice-message-input-button-container .cancel-btn {
    margin-left: auto; 
    background-color: #25272E;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    order: 3;
}

.recording-label {
    font-size: 16px;
    color: white;
}

.recording-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #E31D1D;
    margin-right: 10px;
}
