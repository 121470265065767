/* CSS for ItemsGrid Component */

.square-skill-image {
    width: 80%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
}

.skill-grid-item {
    transition: transform 0.3s ease;
}

.skill-grid-item:hover {
    transform: scale(1.05);
}

.skill-grid-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.skill-item-grid-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: white;
}

.skill-grid-item h4 {
    text-align: left;
    margin-top: 8px;
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

/* Custom CSS to limit columns to 10 on xxl screens */
@media (min-width: 1400px) {
    .skill-items-custom-xxl-grid {
        display: flex;
        flex-wrap: wrap;
        margin: -8px;
    }
    
    .skill-items-custom-xxl-grid .col-xxl-1 {
        flex: 0 0 calc(10% - 16px);
        max-width: calc(10% - 16px);
        margin: 8px;
    }
}

@media (min-width: 768px) and (max-width: 1400px) {
    .skill-items-custom-xxl-grid .col-xxl-1 {
        flex: 0 0 calc(12.5% - 16px);
        max-width: calc(12.5% - 16px);
        margin: 8px;
    }
}