/* Topbar.css */
.navbar {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.profile-type-button {
    border-color: #25272E;
    border-width: 2px;
    border-radius: 12px;
    color: white;
}

.notification-icon {
    width: 24px;
    height: 24px;
    color: white;
}

.avatar {
    width: 40px;
    height: 40px;
    background-color: blue;
    border-radius: 50%;
}

/* Custom Search Input Styles */
.search-container {
    position: relative;
}

.search-input {
    background-color: #25272E;
    color: #B1B5C1;
    padding-left: 40px;
    border-radius: 4px;
    border: none;
    border-radius: 12px;
    transition: background-color 0.3s;
}

.search-input:focus {
    background-color: #25272E;
    color: white;
}

.search-input::placeholder {
    color: #B1B5C1;
}

/* Adding the search icon */
.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    z-index: 10;
}
