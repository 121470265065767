/* ChatMessageList.css */
.chat-message-list {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-messages-container {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 10px;
}

/* Hide the scrollbar on mobile for .chat-messages-container */
@media screen and (max-width: 768px) {
    .chat-messages-container::-webkit-scrollbar {
        display: none;
    }
}

.chat-message-list .header {
    padding: 10px;
    /* Add more styles as needed */
}

.chat-message-list-headline {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.chat-message-list-divider {
    height: 1px;
    align-self: stretch;
    background: #2E2E3F;
}

.chat-message-list-user-name {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 0 !important;
    margin: 0 !important;
}

.chat-message-list-user-role {
    color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding: 0 !important;
    margin: 0 !important;
}

.chat-message-list-status {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding: 0 !important;
    margin: 0 !important;
}

.clickable-icon {
    cursor: pointer;
    color: #B1B5C1;
}

.no-chat-selected-message {
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-radius: 33px;
    background: #2E2E3F;
    padding: 12px 24px;
}

.btn-chat-pay {
    background-color: #6033FF !important;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 16px;
}
