/* SocialList.css */
.social-list-headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: white;
    margin-top: 24px !important;
    margin-bottom: 16px !important;
}

.social-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.social-item-container {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.social-item {
    background-color: #131313;
    color: white;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500; 
    transition: transform 0.3s ease;
}

.social-item:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.delete-social-icon {
    position: absolute;
    top: -15px;
    right: -10px;
    cursor: pointer;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 2;
}

.add-social {
    display: flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    align-items: center;
    background-color: transparent;
    color: white;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px dashed #B1B5C1;
}

.add-icon {
    margin-right: 6px;
}
