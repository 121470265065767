.page-container {
    background-color: #25272E;
    padding: 32px;
    border-radius: 16px;
}

.profile-title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    color: white !important;
}

/* PROFILE PAGE */
.square-profile-image-container {
    width: 100%;
    position: relative;
}

.square-profile-image-container::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.square-profile-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
}

.profile-username {
    color: white;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}

.profile-description {
    color: #B1B5C1;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
}

.profile-statistic-title {
    color: #B1B5C1;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}

.profile-statistic-subtitle {
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.btn-ask-for {
    background-color: #6033FF !important;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 16px;
}

.statistic-icon-box {
    width: 36px;
    height: 36px;
    background-color: #131313;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.statistic-icon-box svg {
    color: white;
}

.statistic-icon-box-label {
    color: #B1B5C1;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    align-self: center;
    padding: 0;
    margin: 0;
}

/* MESSAGES PAGE */
.chat-list-section {
    border-right: 1px solid #2E2E3F;
    height: calc(100vh - 170px);
    padding: 0;
}

.chat-message-list-section {
    height: calc(100vh - 170px);
    padding: 0;
}

.email-verification-message {
    margin-top: 20px;
    padding: 20px;
}

.email-verification-message h3 {
    color: white;
    margin-bottom: 15px;
}

.email-verification-message p {
    color: white;
    line-height: 1.5;
    margin-bottom: 10px;
}
  
@media (max-width: 992px) {
    .chat-message-list-section {
        height: auto;
    }    
    .chat-list-section {
        border: 0;
    }
    .page-container {
        background-color: #25272E;
        padding: 16px;
        border-radius: 16px;
    }    
}
