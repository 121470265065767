.chat-voice-message-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 300px;
    width: 100%;
}

.top-section {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.voice-message {
    display: flex;
    flex-grow: 1;
    align-items: center;
    border-radius: 30px;
    padding: 10px;
    width: 100%;
}

.play-pause-btn {
    background: none;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: white;
}

.waveform-timer-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#waveform {
    flex-grow: 1;
    margin-left: 10px;
}

.timer {
    color: white;
    font-size: 12px;
    margin-top: 5px;
}

@media screen and (max-width: 768px) {
    .chat-voice-message-item-container {
        max-width: none;
        width: 80%;
    }
}
