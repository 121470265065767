.solana-pay-btn {
    height: 60px;
    width: 222px;
    background-color: white;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 19px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}

.solana-pay-btn:hover {
    opacity: 0.9;
}

.solana-icon {
    margin-left: 10px;
    height: 24px;
}
