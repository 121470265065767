.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    backdrop-filter: blur(5px); /* Blurry background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1030;
}

.input-modal-title {
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    color: white !important;
}

.modal-box {
    width: 500px;
    min-width: 400px;
    background-color: #25272E;
    padding: 24px;
    border-radius: 16px;
    color: white;
}

@media (max-width: 768px) {
    .modal-box {
        min-width: 0;
        width: 90%;
    }
}

/* Optional: Additional styling for buttons */
.remove-btn {
    margin-left: auto;
    background-color: #c0392b;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.add-btn {
    margin-left: auto;
    background-color: #6033FF;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.cancel-btn {
    margin-left: auto;
    background-color: #131313;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.input-modal-text-input {
    background-color: #131313;
    color: white;
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: 12px;
    padding: 12px;
}

input:focus,
.input-modal-text-input:focus {   
    background-color: #131313;
    box-shadow: none;
    color: white;
}

.input-modal-text-input::placeholder {
    color: #B1B5C1;
    opacity: 1;
}

/* For cross-browser compatibility */
.input-modal-text-input::-webkit-input-placeholder {
    color: #B1B5C1;
}

.input-modal-text-input:-moz-placeholder {
    color: #B1B5C1;
    opacity: 1;
}

.input-modal-text-input::-moz-placeholder {
    color: #B1B5C1;
    opacity: 1;
}

.input-modal-text-input:-ms-input-placeholder {
    color: #B1B5C1;
}